.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
.min-col {
    width: 1%;
}

.c-loader > .sk-double-bounce.is-loaded{
    opacity:0;
}


/* buscador header para cuando se minimiza el sidebar */
