//
// styles
//

// metronic
//
// Theme style
//

// Initialize
// Custom functions & mixins
@import "../core/sass/base/functions";
@import "../core/sass/base/mixins";
@import "../core/sass/components/mixins";
@import "../core/sass/vendors/plugins/mixins";

// Custom variables
@import "../demo1/sass/components/variables.custom";
//ToDo: cambiar compilacion de plugins para que no se modifique el archivo anterior
@import "variables";
@import "../core/sass/components/variables";

// Bootstrap initializaton
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/utilities";

// 3rd-Party plugins variables
@import "../core/sass/vendors/plugins/variables";

// Custom layout variables
@import "../demo1/sass/layout/variables";

// Components
@import "../core/sass/components/components";
@import "../demo1/sass/components/components";

// Layout
@import "../demo1/sass/layout/layout";

//
//vendors
//

// Font Awesome
$fa-font-path: '../plugins/global/fonts/@fortawesome/';
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

//@import "vendor-extensions/vue-multiselect";
@import "~vue-multiselect/dist/vue-multiselect.min.css";

@import "vendor-extensions/bootstrap-datetimepicker";
@import "~eonasdan-bootstrap-datetimepicker/src/sass/bootstrap-datetimepicker";

// custom styles
@import "global/global-styles";


// bootstrap vue table
@import "~bootstrap-table/src/themes/bootstrap/variables";
@import "~bootstrap-table/src/themes/theme";
@import "vendor-extensions/bootstrap-table";


@import "vendor-extensions/trumbowyg";
