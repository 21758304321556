.trumbowyg-editor{
    background: #fff;
}

.trumbowyg-small{
    .trumbowyg-box,
    .trumbowyg-editor,
    .trumbowyg-editor,
    .trumbowyg-textarea{
        height: auto;
        min-height: 150px;
    }
}
